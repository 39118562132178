import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import "twin.macro";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PaddedContainer from "../components/PaddedContainer";

const AboutPage = ({ data }) => (
  <Layout>
    <SEO title="About" />
    <div tw="bg-gray-100 flex-grow">
      <PaddedContainer tw="py-4">
        <h1 tw="text-3xl font-bold mb-2">About</h1>
        <p tw="mb-2">
          routes.cc is a catalogue of great rides. It’s a companion for your
          adventures with all the local knowledge you need, from climbs to
          cafes.
        </p>
        <p tw="mb-2">
          Every ride on routes.cc is curated - selected from the cycling
          community’s favourites. They are all vetted, and have a proper
          description, suggested stops, and turn-by-turn directions.
        </p>
        <p tw="mb-2">
          Embrace the spirit of adventure on two wheels; pedal somewhere new.
        </p>
        <p tw="mb-6">
          <OutboundLink
            href="https://medium.com/@jonathan.frost/pedalling-somewhere-new-with-routes-cc-fd91a82cc9be"
            tw="text-brand-500 hover:text-brand-400"
          >
            Read more about our launch.
          </OutboundLink>
        </p>
        <h2 tw="text-2xl font-bold mt-4 mb-2">Ride leaders</h2>
        <p tw="mb-2">
          routes.cc is a project by two cyclists living in London. When not
          riding bikes we work in tech.
        </p>
        <p tw="mb-6">
          Drop us a line if you want to recommend a route, or come for a ride:{" "}
          <a
            href="mailto:hello@routes.cc"
            tw="text-brand-500 hover:text-brand-400"
          >
            hello@routes.cc
          </a>
        </p>
        <div tw="flex flex-wrap justify-center">
          {[
            ["jonathan", "Jonathan Frost"],
            ["alex", "Alex Norton", "https://www.alexnorton.com/"]
          ].map(([key, name, url]) => (
            <div key={key} tw="inline-block text-center m-2">
              <GatsbyImage
                image={data[key].childImageSharp.gatsbyImageData}
                tw="border-white border-8"
              />
              <span tw="block font-bold">
                {url ? (
                  <OutboundLink
                    href={url}
                    tw="text-brand-500 hover:text-brand-400"
                  >
                    {name}
                  </OutboundLink>
                ) : (
                  name
                )}
              </span>
            </div>
          ))}
        </div>
      </PaddedContainer>
    </div>
  </Layout>
);

export const query = graphql`
  fragment AboutImage on File {
    childImageSharp {
      gatsbyImageData(
        width: 275
        height: 275
        layout: FIXED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }

  query {
    jonathan: file(relativePath: { eq: "about-jonathan.jpg" }) {
      ...AboutImage
    }
    alex: file(relativePath: { eq: "about-alex.jpg" }) {
      ...AboutImage
    }
  }
`;

export default AboutPage;
